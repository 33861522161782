import React, { useState } from 'react';
import { Button, Col, Row } from 'antd';
import styles from './index.module.less';

import microsoft from 'features/login/images/microsoft_login_button.png';
import { Message, Icon } from 'semantic-ui-react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import { useLocation } from 'react-router-dom';
import tataPro from '../../images/tata_logo.svg';
import money from '../../images/money.svg';
import dirnking_tea_bro from '../../images/dirnking_tea_bro.svg';
import check_icon from '../../images/check_icon.svg';

const LoginPage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [errorMsg] = useState('');
  const dispatch = useDispatch();
  let location = useLocation();
  const data = [
    {
      description: 'Manage fund allocations for invoices'
    },
    {
      description: 'View vendor, project and BU-wise summaries'
    },
    {
      description: 'Get instant reports on allocation requests'
    }
  ];
  return (
    <div className={`${styles.full_container_div}`}>
      <Row className={`${styles.full_container}`}>
        <Col span={7}>
          <Row className={`mb-0 ${styles.money_container}`}>
            <div>
              <img className={`${styles.left_logo}`} src={tataPro} />
            </div>
            <div>
              <img
                className={`mb-0 ${styles.money_container_img}`}
                src={money}
              />
            </div>
          </Row>
        </Col>
        <Col span={17}>
          <div className={`mb-0 ${styles.drinking_bro_container}`}>
            <img
              className={`mb-0 ${styles.drinking_bro_container_img}`}
              src={dirnking_tea_bro}
            />
            <div className={`${styles.overlay_image}`}>
              <div>
                <div className={`${styles.text_container_one}`}>
                  <span className={`${styles.text_container_one_span}`}>
                    {' '}
                    Welcome to Fund Central!
                  </span>
                </div>
                <div className={`${styles.text_container_two}`}>
                  Manage fund allocations for invoices
                </div>
                <div>
                  {data.map((item, index) => (
                    <div
                      className={`${styles.check_item_container}`}
                      key={index}
                    >
                      <div style={{ width: '500px' }}>
                        <span>
                          <img
                            className={`${styles.check_item_img1}`}
                            src={check_icon}
                          />
                        </span>
                        <span className={`${styles.check_item_text}`}>
                          {' '}
                          {item.description}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={`${styles.login_button_div}`}>
                {' '}
                <Button
                  className={`${styles.login_button}`}
                  src={microsoft}
                  id="microsoft-login"
                  centered="true"
                  alt="Microsoft"
                  onClick={() => {
                    const user_details = LocalStorage.getItem('A2P_user');
                    console.log(user_details ? user_details : 'No response');
                    instance
                      .loginPopup({
                        scopes: ['User.Read']
                      })
                      .then((result) => {
                        return dispatch(login({ token: result.accessToken }));
                      })
                      .then((result) => {
                        if (result.payload.success) {
                          LocalStorage.setItem(
                            'A2P_user',
                            JSON.stringify(result.payload)
                          );
                          const user = result.payload;

                          if (user.user_details.role === 'Project Engineer') {
                            location.state?.from?.pathname
                              ? history.push(
                                  `${location.state?.from?.pathname}`
                                )
                              : history.push('/invoices');
                          } else if (
                            user.user_details.role === 'SBU Commercial'
                          ) {
                            location.state?.from?.pathname
                              ? history.push(
                                  `${location.state?.from?.pathname}`
                                )
                              : history.push('/invoices');
                          } else if (user.user_details.role === 'SBU Head') {
                            location.state?.from?.pathname
                              ? history.push(
                                  `${location.state?.from?.pathname}`
                                )
                              : history.push('/invoices');
                          } else if (user.user_details.role === 'Treasury') {
                            location.state?.from?.pathname
                              ? history.push(
                                  `${location.state?.from?.pathname}`
                                )
                              : history.push('/invoices');
                          } else if (user.user_details.role === 'Finance CoE') {
                            location.state?.from?.pathname
                              ? history.push(
                                  `${location.state?.from?.pathname}`
                                )
                              : history.push('/financeCoE');
                          }
                        } else {
                          console.log('$$$$$$$$$$$$$$$$$$$');
                        }
                      })
                      .catch((err) => {
                        console.log('noresponse', err, err);
                        isAuthenticated
                          ? instance.logoutRedirect({
                              account: LocalStorage.homeAccountId,
                              postLogoutRedirectUri: '/',
                              mainWindowRedirectUri: '/'
                            })
                          : '';
                      });
                  }}
                >
                  <b className="sfprotext-bold"> Go to your Account</b>
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
