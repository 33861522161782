import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Invoices from 'features/invoices';
import InvoicesSummary from 'features/invoices/components/InvoicesSummary';
import BuVendorList from 'features/invoices/components/BuVendorList/index';
import BuHeadVendorList from 'features/invoices/components/BuHeadVendorList/index';
import TreasuryProjectList from 'features/invoices/components/treasury/TreasuryProjectList/index';
import TreasuryVendorList from 'features/invoices/components/treasury/TreasuryVendorList/index';
import FinanceCoE from 'features/invoices/components/FinanceCoE/index';
import Login from './features/login/index';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import ChartView from 'features/invoices/components/chartView';

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    //redirectUri: 'https://a2p.lexyslabs.com/session.html'
    //redirectUri: 'https://fc-dev.tataprojects.com/session.html'
    redirectUri: 'https://fundcentral.tataprojects.com/session.html'
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};
const pca = new PublicClientApplication(configuration);
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

const roleBasedNavigation = (id) => {
  switch (id) {
    case 'Project Engineer':
      return '/invoices';
    case 'BU Commercial':
      return '/invoices';
    case 'Treasury':
      return '/invoices';
    case 'Finance CoE':
      return '/financeCoE';
    default:
      return '/';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loggedIn } = getLoggedInUser();

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
const AuthChecker = ({ children }) => {
  let location = useLocation();
  const { loggedIn, user } = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : roleBasedNavigation(user?.user_details?.role || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const { user } = getLoggedInUser();
  let goTo = location.state?.from?.pathname
    ? location.state?.from?.pathname
    : user?.user_details?.role
    ? roleBasedNavigation(user?.user_details?.role || '')
    : '/login';
  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />
        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />
        <Route
          path="invoices"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Invoices />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="summary"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <InvoicesSummary />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="vendorList/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <BuVendorList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="buHeadVendorList/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <BuHeadVendorList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="project/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <TreasuryProjectList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="vendor/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <TreasuryVendorList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="financeCoE"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <FinanceCoE />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ChartView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
